<template>
	<div @dragleave.prevent="draggingElementEvent(-1, '', $event)" class="py-4 leading-relaxed transition duration-300">
		<div v-if="props.schema.length === 0">
			<div
				class="rounded-lg border-dashed border-2 h-64 flex flex-col justify-center items-center"
				:class="{
					'border-primary': draggingFormElement && hovering == 0 + '_top',
				}"
				:style="{ 'z-index': zIndex }"
				@dragover.prevent="draggingElementEvent(0, 'top', $event)"
				@drop.capture="draggingElementEvent(0, 'top', $event)"
			>
				<FontAwesomeIcon :icon="faDiagramNext" aria-hidden="true" class="h-24 w-24 text-gray-500" />
				<h1 class="select-none mt-4 text-gray-600 font-medium text-2xl">Drag your first element in to start building your new form!</h1>
			</div>
		</div>
		<div class="grid grid-cols-12 gap-x-2 gap-y-4">
			<div
				v-for="(element, index) in schema"
				draggable="true"
				@dragstart.self="start(element, index, elementAddress)"
				:key="element.key"
				class="border-dashed border relative"
				:style="{ 'z-index': zIndex }"
				:class="{
					...{
						'border-sky-500': ((!elementAddress && formStore.editingElementIndex === index) || formStore.editingElementAddress === elementAddress + '[' + index + ']') && baseStore.secondaryPanelOpen,
						'hover:border-primary': ((!elementAddress && formStore.editingElementIndex !== index) || formStore.editingElementAddress !== elementAddress + '[' + index + ']') && !nestedHovering,
					},
					...element['outerClass'],
					...findClassValueByPrefix(element?.attrs?.class, 'col'),
					...findClassValueByPrefix(element?.attrs?.class, 'sm:'),
					...findClassValueByPrefix(element?.attrs?.class, 'lg:'),
					...findClassValueByPrefix(element?.attrs?.class, 'xl:'),
				}"
			>
				<template v-if="isSchemaObject(element) && element['$formkit'] && element['$formkit'] == 'repeater'">
					<div
						v-if="!draggingFormElement"
						class="cursor-pointer absolute inset-0 w-full -top-4"
						style="height: calc(100% + 17px)"
						:style="{ 'z-index': zIndex + 10 }"
						@mouseenter="hovering = String(element.key)"
						@mouseleave="hovering = ''"
						@click="editElement(index)"
					></div>
					<div v-if="draggingFormElement && hovering == index + '_top'">
						<div class="absolute inset-0 w-[46%] -top-2 border-t-2 border-primary pr-10" :style="{ 'z-index': zIndex + 10 }" />
						<div class="absolute inset-0 w-full -top-2 flex justify-center" :style="{ 'z-index': zIndex + 10 }">
							<span class="-translate-y-3.5 font-medium"> Drop here! </span>
						</div>
						<div class="absolute w-[46%] -top-2 border-t-2 border-primary pl-10 -right-0" :style="{ 'z-index': zIndex + 10 }" />
					</div>
					<div v-if="draggingFormElement && hovering == index + '_bottom'">
						<div class="absolute w-[46%] -bottom-2 border-b-2 border-primary pr-10" :style="{ 'z-index': zIndex + 10 }" />
						<div class="absolute w-full -bottom-2 flex justify-center" :style="{ 'z-index': zIndex + 10 }">
							<span class="translate-y-3 font-medium"> Drop here! </span>
						</div>
						<div class="absolute w-[46%] -bottom-2 border-b-2 border-primary pl-10 -right-0" :style="{ 'z-index': zIndex + 10 }" />
					</div>
					<div
						v-if="draggingFormElement"
						class="cursor-pointer absolute inset-0 w-full -top-4"
						style="height: 30px"
						:style="{ 'z-index': zIndex + 10 }"
						@dragover.prevent="draggingElementEvent(index, 'top', $event)"
						@drop.capture="draggingElementEvent(index, 'top', $event)"
					></div>
					<div
						v-if="draggingFormElement"
						class="cursor-pointer absolute w-full -bottom-4"
						style="height: 30px"
						:style="{ 'z-index': zIndex + 10 }"
						@dragover.prevent="draggingElementEvent(index, 'bottom', $event)"
						@drop.capture="draggingElementEvent(index, 'bottom', $event)"
					></div>
					<FakeNestedContainer :label="element.label" :helper="element.help" :add-label="element['add-label']" :z-index="props.zIndex">
						<SchemaBuilder
							:schema="element.children as FormKitSchemaObject[]"
							:dragging-form-element="draggingFormElement"
							:element-address="elementAddress ? elementAddress + '[' + index + '].children' : '[' + index + '].children'"
							:z-index="zIndex + 20"
							@mouseenter="nestedHovering = true"
							@mouseleave="nestedHovering = false"
							@sorting="start"
							@dropped="
								(eventLocationIndex: number | NestedLocation, eventLocationArea: string) =>
									droppedFromNestedElement(index, eventLocationIndex, eventLocationArea, elementAddress ? elementAddress + '[' + index + '].children' : '[' + index + '].children')
							"
							@dropped-nested="(eventLocationIndex: number | NestedLocation, eventLocationArea: string) => droppedFromNestedElement(index, eventLocationIndex, eventLocationArea)"
						></SchemaBuilder>
					</FakeNestedContainer>
				</template>
				<template v-else>
					<!--suppress JSUnusedLocalSymbols -->
					<FormKitSchema :schema="(({ ['if']: _, ...schema }) => schema)(element) as FormKitSchemaNode" :style="{ 'z-index': zIndex }" />
					<div
						v-if="!draggingFormElement"
						class="cursor-pointer absolute inset-0 w-full -top-4"
						style="height: calc(100% + 17px)"
						:style="{ 'z-index': zIndex + 10 }"
						@mouseenter="hovering = String(element.key)"
						@mouseleave="hovering = ''"
						@click="editElement(index)"
					></div>

					<div v-if="draggingFormElement && hovering == index + '_top'">
						<div class="absolute inset-0 w-[46%] -top-2 border-t-2 border-primary pr-10" :style="{ 'z-index': zIndex + 10 }" />
						<div class="absolute inset-0 w-full -top-2 flex justify-center" :style="{ 'z-index': zIndex + 10 }">
							<span class="-translate-y-3.5 font-medium"> Drop here! </span>
						</div>
						<div class="absolute w-[46%] -top-2 border-t-2 border-primary pl-10 -right-0" :style="{ 'z-index': zIndex + 10 }" />
					</div>
					<div v-if="draggingFormElement && hovering == index + '_bottom'">
						<div class="absolute w-[46%] -bottom-2 border-b-2 border-primary pr-10" :style="{ 'z-index': zIndex + 10 }" />
						<div class="absolute w-full -bottom-2 flex justify-center" :style="{ 'z-index': zIndex + 10 }">
							<span class="translate-y-3 font-medium"> Drop here! </span>
						</div>
						<div class="absolute w-[46%] -bottom-2 border-b-2 border-primary pl-10 -right-0" :style="{ 'z-index': zIndex + 10 }" />
					</div>
					<div
						v-if="draggingFormElement"
						class="cursor-pointer absolute inset-0 w-full -top-4 element_dropzone"
						style="height: calc(50% + 17px)"
						:style="{ 'z-index': zIndex + 20 }"
						@dragover.prevent="draggingElementEvent(index, 'top', $event)"
						@drop.capture="draggingElementEvent(index, 'top', $event)"
					></div>
					<div
						v-if="draggingFormElement"
						class="cursor-pointer absolute w-full -bottom-4"
						style="height: calc(50% + 17px)"
						:style="{ 'z-index': zIndex + 20 }"
						@dragover.prevent="draggingElementEvent(index, 'bottom', $event)"
						@drop.capture="draggingElementEvent(index, 'bottom', $event)"
					></div>
				</template>

				<div
					v-show="hovering == element.key"
					class="cursor-pointer absolute -left-1 ml-px top-0 transform -translate-y-full text-white flex"
					:style="{ 'z-index': zIndex + 20 }"
					@mouseenter="hovering = String(element.key)"
					@mouseleave="hovering = ''"
				>
					<!-- Name -->
					<div v-show="element.name" class="px-1 h-4 flex items-center justify-center bg-primary-500 text-xs ml-0.5 -mr-px cursor-pointer transition-colors hover:bg-primary-600" :style="{ 'z-index': zIndex + 20 }">
						{{ element.name }}
					</div>
					<div
						v-if="element.meta && element.meta?.note"
						class="h-4 w-4 flex items-center justify-center bg-primary-500 text-xs ml-1 -mr-px cursor-pointer transition-colors hover:bg-primary-600 group relative"
						:style="{ 'z-index': zIndex + 20 }"
					>
						<FontAwesomeIcon :icon="faInfo" aria-hidden="true" class="inline-block text-inherit h-1em overflow-visible -align-0.125em" />
						<div class="absolute group-hover:block top-[50%] -right-[100%] -translate-y-1/2 -translate-x-1/2 border-8 border-y-transparent border-l-transparent border-r-black flex" :style="{ 'z-index': zIndex + 20 }"></div>
						<div class="absolute hidden group-hover:block -top-2 -right-[0.50rem] translate-x-full" :style="{ 'z-index': zIndex + 20 }">
							<p class="flex1 text-left justify-start whitespace-pre-wrap px-2 py-1 bg-black border-black rounded-lg text-white text-sm min-w-[32rem] max-w-2xl" :style="{ 'z-index': zIndex + 20 }">{{ element.meta?.note }}</p>
						</div>
					</div>
				</div>

				<div
					v-show="hovering == element.key"
					class="cursor-pointer absolute right-0 top-0 transform -translate-y-full text-white flex"
					:style="{ 'z-index': zIndex + 20 }"
					@mouseenter="hovering = String(element.key)"
					@mouseleave="hovering = ''"
					@click="deleteElement(index)"
				>
					<!-- Remove -->
					<div class="w-4 h-4 flex items-center justify-center bg-primary-500 text-xs ml-0.5 -mr-px cursor-pointer transition-colors hover:bg-primary-600">
						<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="inline-block text-inherit h-1em overflow-visible -align-0.125em" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { defineProps, ref, withDefaults } from 'vue';
	import { FormKitSchemaNode } from '@formkit/core';
	import { useFormStore } from '@modules/form/store';
	import { useBaseStore } from '@store';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faDiagramNext } from '@fortawesome/pro-light-svg-icons';
	import { faInfo, faXmark } from '@fortawesome/pro-solid-svg-icons';
	import { Class, FormKitSchemaObject, isSchemaObject, NestedLocation } from '@/types/form';
	import FakeNestedContainer from '@modules/form/components/Elements/FakeNestedContainer.vue';

	const hovering = ref('');
	const nestedHovering = ref(false);

	const formStore = useFormStore();
	const baseStore = useBaseStore();

	interface Props {
		schema: FormKitSchemaObject[];
		draggingFormElement: boolean;
		elementAddress?: undefined | string;
		zIndex?: number;
	}

	const props = withDefaults(defineProps<Props>(), {
		elementAddress: undefined,
		zIndex: 20,
	});

	type StringKeyedObject = { [key: string]: string };
	const findClassValueByPrefix = (object: Class | unknown, prefix: string) => {
		const returnObject = <StringKeyedObject>{};
		for (const property in object as any) {
			if (Object.hasOwn(object as any, property) && property.toString().startsWith(prefix)) {
				returnObject[property] = (object as any)[property];
			}
		}

		return returnObject;
	};

	const editElement = (elementIndex: number) => {
		formStore.openFormkitElementEditor(elementIndex, props.elementAddress);
	};

	const deleteElement = (elementIndex: number) => {
		formStore.deleteElementFromActiveSchema(elementIndex, props.elementAddress);
	};

	const emit = defineEmits<{
		(e: 'dropped', index: number | NestedLocation, area: string): void;
		(e: 'droppedNested', index: number | NestedLocation, area: string): void;
		(e: 'sorting', element: FormKitSchemaObject, index: number | NestedLocation, address: string | undefined): void;
	}>();

	const draggingElementEvent = async (eventLocationIndex: number, eventLocationArea: string, nativeEvent: DragEvent) => {
		nativeEvent.preventDefault();
		hovering.value = eventLocationIndex + '_' + eventLocationArea;
		if (nativeEvent.type === 'drop') {
			emit('dropped', eventLocationIndex, eventLocationArea);
		}
	};

	const droppedFromNestedElement = async (parentIndex: number, eventLocationIndex: number | NestedLocation, eventLocationArea: string, elementAddress: string | undefined = undefined) => {
		if (typeof eventLocationIndex === 'number') {
			emit(
				'droppedNested',
				{
					index: eventLocationIndex,
					insertAt: eventLocationArea !== 'top' ? eventLocationIndex + 1 : eventLocationIndex,
					elementAddress: elementAddress,
				} as NestedLocation,
				eventLocationArea,
			);
		} else {
			emit('droppedNested', eventLocationIndex, eventLocationArea);
		}
	};
	const start = (element: FormKitSchemaObject, index: number, address: string | undefined) => {
		console.dir('starting');
		emit('sorting', element, index, address);
	};
</script>
