<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="numberSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BaseBuilderOptions, BaseConditionalOptions, BaseDesignOptions, BaseRequiredOptions, BasicOptionalOptions, BuildElementBox } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = formStore.editingElement;

	const numberSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Required options', BaseRequiredOptions),
		...BuildElementBox('Optional parameters', BasicOptionalOptions),
		...BuildElementBox('Design parameters', BaseDesignOptions),
		...BuildElementBox('File input parameters', [
			{
				$formkit: 'toggle',
				name: 'multiple',
				label: 'Allow multiple files',
				preserve: true,
				help: 'Should this input allow multiple files to be uploaded or just 1',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-12': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'dropdown',
				name: 'accept',
				label: 'Accepted file types',
				preserve: true,
				help: 'What file types should be allowed to be uploaded. (optional)',
				options: {
					'*': 'Any file type',
					'audio/*': 'Audio files',
					'video/*': 'Video files',
					'image/*': 'Image files',
					'.json, .pdf, .doc, .docx, .xml, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Document types',
				},
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-12': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Conditional display', BaseConditionalOptions),
		...BuildElementBox('Builder parameters', BaseBuilderOptions),
	];

	watch(
		() => data.name,
		(newNameValue) => {
			data.id = newNameValue;
		}
	);
</script>
