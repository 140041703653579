<template>
	<div class="h-full flex flex-col">
		<div class="h-full px-4 overflow-y-auto">
			<FormKit id="formSettings" v-model="data" type="form" :actions="false" class="flex w-full">
				<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
					<FormKitSchema :schema="formSettingsSchema" />
				</div>
			</FormKit>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { FormKitSchemaNode } from '@formkit/core';
	import { BuildElementBox } from '@modules/form/utils/options';
	import { ref, watch } from 'vue';
	import slugify from '@utils/useSlugify';
	import { useFormStore } from '@modules/form/store';

	const formStore = useFormStore();

	const data = ref(formStore.activeForm);

	watch(
		() => formStore.activeForm.name,
		(newValue) => {
			if (newValue) {
				formStore.activeForm.reference_name = slugify(newValue);
			}
		}
	);

	const formSettingsSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Global form settings', [
			{
				$formkit: 'text',
				outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true, 'px-1': true },
				validation: [['required'], ['length:0,255']],
				id: 'name',
				name: 'name',
				label: 'Workflow name',
				placeholder: 'Enter a name for the form',
			},
			{
				$formkit: 'text',
				outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true, 'px-1': true },
				validation: [['required'], ['length:0,255']],
				id: 'reference_name',
				name: 'reference_name',
				label: 'Form reference name',
				readonly: true,
				disabled: true,
			},
			{
				$formkit: 'text',
				outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'px-1': true },
				id: 'description',
				name: 'description',
				label: 'Form description',
				placeholder: 'Enter a description for the form',
			},
		]),
	];
</script>
