<template>
	<div class="h-full flex flex-col">
		<div class="flex flex-row justify-end items-center space-x-4 overflow-visible sticky top-0 z-20 border-b border-dashed bg-gray-50">
			<button type="button" class="items-center text-sm font-medium rounded-md focus:outline-none text-danger-400 underline mr-3 my-2" @click="deleteElement">Delete this element</button>
		</div>
		<div v-if="formStore.elementSettingsOpen && formStore.editingElement" class="h-full px-4 py-2 overflow-y-auto">
			<StaticTextElement
				v-if="
					formStore.editingElement &&
					formStore.editingElement.$el &&
					(formStore.editingElement.$el === 'h1' ||
						formStore.editingElement.$el === 'h2' ||
						formStore.editingElement.$el === 'h3' ||
						formStore.editingElement.$el === 'h4' ||
						formStore.editingElement.$el === 'h5' ||
						formStore.editingElement.$el === 'p')
				"
				:key="formStore.editingElementAddress"
			></StaticTextElement>
			<QuoteElement v-else-if="formStore.editingElement && formStore.editingElement.$el && formStore.editingElement?.$el === 'blockquote'" :key="formStore.editingElementAddress"></QuoteElement>
			<DividerElement
				v-else-if="formStore.editingElement && formStore.editingElement.$el && formStore.editingElement?.$el === 'div' && formStore.editingElement?.meta?.type === 'divider'"
				:key="formStore.editingElementAddress"
			></DividerElement>
			<DisplayElement
				v-else-if="formStore.editingElement && formStore.editingElement.$el && formStore.editingElement?.$el === 'div' && formStore.editingElement?.meta?.type === 'display'"
				:key="formStore.editingElementAddress"
			></DisplayElement>
			<SpacerElement v-else-if="formStore.editingElement && formStore.editingElement.$el && formStore.editingElement.$el === 'div' && formStore.editingElement?.meta?.type === 'spacer'" :key="formStore.editingElementAddress"></SpacerElement>
			<TextElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'text'" :key="formStore.editingElementAddress" />
			<TextElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'password'" :key="formStore.editingElementAddress" />
			<TextElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'email'" :key="formStore.editingElementAddress" />
			<FileElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'file'" :key="formStore.editingElementAddress" />
			<NumberElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'number'" :key="formStore.editingElementAddress" />
			<NumberElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'range'" :key="formStore.editingElementAddress" />
			<NumberElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'stepper'" :key="formStore.editingElementAddress" />
			<ToggleElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'toggle'" :key="formStore.editingElementAddress" />
			<SelectElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && (formStore.editingElement.$formkit === 'select' || formStore.editingElement.$formkit === 'dropdown')" :key="formStore.editingElementAddress" />
			<TaglistElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'taglist'" :key="formStore.editingElementAddress" />
			<DateTimeElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'datetime-local'" :key="formStore.editingElementAddress" />
			<DateTimeElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'date'" :key="formStore.editingElementAddress" />
			<DateTimeElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'time'" :key="formStore.editingElementAddress" />
			<TextareaElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'textarea'" :key="formStore.editingElementAddress" />
			<TelephoneElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'telephone'" :key="formStore.editingElementAddress" />
			<CheckboxElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'checkbox'" :key="formStore.editingElementAddress" />
			<RadioElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'radio'" :key="formStore.editingElementAddress" />
			<RatingElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'rating'" :key="formStore.editingElementAddress" />
			<RepeaterElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'repeater'" :key="formStore.editingElementAddress" />
			<CardGroupElement v-else-if="formStore.editingElement && formStore.editingElement.$formkit && formStore.editingElement.$formkit === 'cardGroup'" :key="formStore.editingElementAddress" />
		</div>
		<div class="flex flex-col justify-center border-t pt-2 px-2 pb-2">
			<DebugSection>
				<pre>{{ formStore.getEditingElement() }}</pre>
			</DebugSection>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useFormStore } from '@modules/form/store';
	import TextElement from './Elements/TextElement.vue';
	import ToggleElement from './Elements/ToggleElement.vue';
	import NumberElement from './Elements/NumberElement.vue';
	import DateTimeElement from './Elements/DateTimeElement.vue';
	import TextareaElement from './Elements/TextareaElement.vue';
	import SelectElement from './Elements/SelectElement.vue';
	import TelephoneElement from '@modules/form/components/Elements/TelephoneElement.vue';
	import CheckboxElement from '@modules/form/components/Elements/CheckboxElement.vue';
	import StaticTextElement from '@modules/form/components/Elements/StaticTextElement.vue';
	import DividerElement from '@modules/form/components/Elements/DividerElement.vue';
	import QuoteElement from '@modules/form/components/Elements/QuoteElement.vue';
	import RadioElement from '@modules/form/components/Elements/RadioElement.vue';
	import TaglistElement from '@modules/form/components/Elements/TaglistElement.vue';
	import RatingElement from '@modules/form/components/Elements/RatingElement.vue';
	import DisplayElement from '@modules/form/components/Elements/DisplayElement.vue';
	import SpacerElement from '@modules/form/components/Elements/SpacerElement.vue';
	import FileElement from '@modules/form/components/Elements/FileElement.vue';
	import RepeaterElement from '@modules/form/components/Elements/RepeaterElement.vue';
	import CardGroupElement from '@modules/form/components/Elements/CardGroupElement.vue';
	import DebugSection from '@components/DebugSection.vue';
	const formStore = useFormStore();

	const deleteElement = () => {
		formStore.deleteElementFromActiveSchema(formStore.editingElementIndex, formStore.editingElementPath);
	};
</script>
