<template>
	<div class="flex flex-col flex-grow justify-center">
		<div class="flex flex-row justify-center">
			<iframe ref="iframeForm" :src="getPreviewURL" :height="height" :width="width"></iframe>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, defineProps, ref } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import clone from '@utils/useClone';

	const formStore = useFormStore();

	interface Props {
		width: string;
		height: string;
	}

	defineProps<Props>();

	const iframeForm = ref();

	const getPreviewURL = computed<string>((): string => {
		const currentSchema = clone(formStore.activeSchema);
		return '/form/embedded/' + btoa(JSON.stringify(currentSchema));
	});
</script>
