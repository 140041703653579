<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="numberSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BaseBuilderOptions, BaseConditionalOptions, BaseDesignOptions, BaseRequiredOptions, BaseValidationOptions, BuildElementBox, OptionalOptions } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = formStore.editingElement;

	const numberSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Required options', BaseRequiredOptions),
		...BuildElementBox('Optional parameters', OptionalOptions),
		...BuildElementBox('Design parameters', BaseDesignOptions),
		...BuildElementBox('Number input parameters', [
			{
				$formkit: 'number',
				name: 'min',
				label: 'Min number',
				preserve: true,
				help: 'What is the minimum number acceptable. (optional)',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-4': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'number',
				name: 'max',
				label: 'Max number',
				preserve: true,
				help: 'What is the maximum number acceptable. (optional)',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-4': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'number',
				name: 'step',
				label: 'Number step',
				preserve: true,
				value: 'auto',
				help: 'What is the step that the number should be changeable by. (optional)',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-4': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Validation parameters', BaseValidationOptions),
		...BuildElementBox('Conditional display', BaseConditionalOptions),
		...BuildElementBox('Builder parameters', BaseBuilderOptions),
	];

	watch(
		() => data.name,
		(newNameValue) => {
			data.id = newNameValue;
		}
	);
</script>
