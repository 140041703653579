<template>
	<div class="flex flex-col flex-grow justify-center">
		<FormKit v-model="formData" type="form" :no-empty-params="true" :actions="false">
			<div class="grid grid-cols-12 gap-x-2 my-4">
				<FormKitSchema :schema="formStore.activeSchema as FormKitSchemaNode[]" :data="formData" />
			</div>
		</FormKit>
		<hr class="my-3" />
		<h2 class="text-lg">Live form data</h2>
		<pre class="whitespace-pre-wrap">{{ cleanFormData(formData) }}</pre>
	</div>
</template>

<script setup lang="ts">
	import { ref } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { cleanFormData } from '@modules/form/utils/helpers';
	import { FormKitSchema } from '@formkit/vue';
	import { FormKitSchemaNode } from '@formkit/core';

	const formStore = useFormStore();
	const formData = ref({});
</script>
