<template>
	<div class="flex flex-col flex-grow justify-center">
		<div class="flex flex-row justify-center">
			<span class="relative z-0 inline-flex shadow-sm rounded-md">
				<button
					type="button"
					class="relative inline-flex items-center px-8 py-2 rounded-l-md border text-sm font-medium hover:bg-gray-100 focus:z-10 focus:outline-none"
					:class="{
						'bg-primary-600': codeMode === CodeMode.Manual,
						'bg-white': codeMode !== CodeMode.Manual,
						'hover:bg-primary-700': codeMode === CodeMode.Manual,
						'text-white': codeMode === CodeMode.Manual,
						'text-gray-700': codeMode !== CodeMode.Manual,
						'border-transparent': codeMode === CodeMode.Manual,
						'border-gray-300': codeMode !== CodeMode.Manual,
					}"
					@click="codeMode = CodeMode.Manual"
				>
					Manual
				</button>
				<button
					type="button"
					class="-ml-px relative inline-flex items-center px-8 py-2 rounded-r-md border text-sm font-medium hover:bg-gray-100 focus:z-10 focus:outline-none"
					:class="{
						'bg-primary-600': codeMode === CodeMode.Display,
						'bg-white': codeMode !== CodeMode.Display,
						'hover:bg-primary-700': codeMode === CodeMode.Display,
						'text-white': codeMode === CodeMode.Display,
						'text-gray-700': codeMode !== CodeMode.Display,
						'border-transparent': codeMode === CodeMode.Display,
						'border-gray-300': codeMode !== CodeMode.Display,
					}"
					@click="codeMode = CodeMode.Display"
				>
					Display
				</button>
			</span>
		</div>
		<div v-if="codeMode == CodeMode.Display" class="flex flex-row">
			<div class="flex flex-col w-full">
				<FormKit
					v-model="codeViewMode"
					type="dropdown"
					name="viewMode"
					label="Output format"
					:options="[
						{ label: 'Pretty', value: CodeViewMode.Pretty },
						{ label: 'Flat', value: CodeViewMode.Flat },
					]"
				/>
				<hr class="my-4" />
				<div class="flex flex-row justify-center text-sm mb-2">
					<p>Click to copy</p>
				</div>

				<span v-if="codeViewMode == CodeViewMode.Flat" class="bg-gray-100 p-3 rounded-md hover:bg-gray-50 cursor-pointer hover:border-gray-400 hover:border" @click="copySchemaToClipboard(JSON.stringify(formStore.activeSchema))">{{
					formStore.activeSchema
				}}</span>
				<pre v-else class="bg-gray-100 p-3 rounded-md hover:bg-gray-50 cursor-pointer hover:border-gray-400 hover:border" @click="copySchemaToClipboard(JSON.stringify(formStore.activeSchema, null, 4))">{{ formStore.activeSchema }}</pre>
			</div>
		</div>
		<div v-else class="flex flex-row">
			<div class="flex flex-col w-full">
				<div class="flex flex-row w-full justify-end">
					<button
						type="button"
						class="relative inline-flex items-center px-4 py-2 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent"
						@click="saveManualCodeData"
					>
						Save changes
						<FontAwesomeIcon :icon="faFloppyDisk" class="h-5 w-5 ml-3" aria-hidden="true" />
					</button>
				</div>
				<div class="grid grid-cols-12 gap-x-2 my-4 w-full">
					<FormKit
						v-model="codeData"
						type="textarea"
						label="Schema"
						rows="25"
						:classes="{
							outer: 'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-12',
						}"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
	import { ref } from 'vue';
	import useNotify from '@utils/useNotify';
	import { AlertIcons } from '@/types/dialog';
	import useDialog from '@utils/useDialog';
	import { useFormStore } from '@modules/form/store';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

	const enum CodeMode {
		Manual = 'Manual',
		Display = 'Display',
	}

	const enum CodeViewMode {
		Pretty = 'Pretty',
		Flat = 'Flat',
	}

	const formStore = useFormStore();
	const codeData = ref<string>(JSON.stringify(formStore.activeSchema, null, 4));
	const codeMode = ref<string>(CodeMode.Display);
	const codeViewMode = ref<string>(CodeViewMode.Pretty);

	async function copySchemaToClipboard(schema: string) {
		try {
			await navigator.clipboard.writeText(schema);
			useNotify.icon({ icon: AlertIcons.Success }).title('Copied to clipboard').fire();
		} catch (err) {
			alert('Unable to copy the schema to the clipboard!');
		}
	}

	async function saveManualCodeData() {
		try {
			const newSchema = JSON.parse(codeData.value);
			await formStore.makeUpdateToActiveSchema(newSchema);
		} catch (err: unknown) {
			await useDialog.title('Whoaaa hold up there!').icon({ icon: AlertIcons.Error, colour: 'red' }).footer(err.message.toString()).alert('This schema is not valid!!!');
		}
	}
</script>