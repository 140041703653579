<template>
	<FormKit v-model="data" type="form" :preserve="true" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="textAreaSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BaseDesignOptions, OptionalOptions, BaseRequiredOptions, BaseValidationOptions, BuildElementBox, BaseBuilderOptions, BaseConditionalOptions } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = formStore.editingElement;

	const textAreaSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Required options', BaseRequiredOptions),
		...BuildElementBox('Optional parameters', OptionalOptions),
		...BuildElementBox('Design parameters', BaseDesignOptions),
		...BuildElementBox('Textarea input parameters', [
			{
				$formkit: 'number',
				name: 'maxlength',
				label: 'Max length',
				min: 0,
				step: 1,
				help: 'What is the maximum length (in number of characters) of the input field. (optional)',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'number',
				name: 'minlength',
				label: 'Min length',
				min: 0,
				step: 1,
				help: 'What is the minimum length (in number of characters) of the input field. (optional)',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'number',
				name: 'rows',
				label: 'Number of rows to display',
				help: 'What is the maximum number of rows to display of the textarea field. (optional)',
				min: 0,
				step: 1,
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'number',
				name: 'cols',
				label: 'Number of columns to display',
				help: 'What is the maximum number of columns to display of the textarea field. (optional)',
				min: 0,
				step: 1,
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Validation parameters', BaseValidationOptions),
		...BuildElementBox('Conditional display', BaseConditionalOptions),
		...BuildElementBox('Builder parameters', BaseBuilderOptions),
	];

	watch(
		() => data.name,
		(newNameValue) => {
			data.id = newNameValue;
		},
	);
</script>
