<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="staticElementSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { ref, watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BuildElementBox, StaticDesignOptions } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = ref({
		content: (Array.isArray(formStore.editingElement?.children) ? formStore.editingElement.children[0].children : formStore.editingElement?.children) ?? '',
		style: formStore.editingElement?.attrs?.style ?? '',
		class: formStore.editingElement?.attrs?.class ?? '',
	});

	watch(
		() => {
			return data.value.content;
		},
		(newContent) => {
			const editingFormElement = formStore.getEditingElement();
			if (Array.isArray(editingFormElement.children)) {
				editingFormElement.children[0].children = newContent;
			} else {
				editingFormElement.children = newContent;
			}
		},
	);

	const staticElementSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Required options', [
			{
				$formkit: 'text',
				name: 'content',
				label: 'Content',
				preserve: true,
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-12': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Content parameters', [
			{
				$formkit: 'classes',
				name: 'class',
				label: 'Text size',
				preserve: true,
				staticElement: true,
				classOptions: {
					'text-xs': 'Extra Small',
					'text-sm': 'Small',
					'text-md': 'Medium',
					'text-base': 'Normal',
					'text-lg': 'Large',
					'text-xl': 'Extra Large',
					'text-2xl': '2x Extra Large',
					'text-3xl': '3x Extra Large',
					'text-4xl': '4x Extra Large',
					'text-5xl': '5x Extra Large',
					'text-6xl': '6x Extra Large',
					'text-7xl': '7x Extra Large',
					'text-8xl': '8x Extra Large',
					'text-9xl': '9x Extra Large',
				},
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'colour',
				name: 'style',
				label: 'Text colour',
				preserve: true,
				styleAttribute: 'color',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Design parameters', StaticDesignOptions),
	];
</script>
