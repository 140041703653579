<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="textSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BaseBuilderOptions, BaseConditionalOptions, BaseDesignOptions, BaseExpressionOptions, BaseRequiredOptions, BaseValidationOptions, BuildElementBox, OptionalOptions } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = formStore.editingElement;

	const textSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Required options', BaseRequiredOptions),
		...BuildElementBox('Optional parameters', [
			...OptionalOptions,
			...[
				{
					$formkit: 'text',
					name: 'value',
					label: 'Initial text',
					help: 'What is the initial value of the input field. (optional)',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-6': true,
						'px-1': true,
					},
				},
			],
		]),
		...BuildElementBox('Design parameters', BaseDesignOptions),
		...BuildElementBox('Text input parameters', [
			{
				$formkit: 'number',
				name: 'maxlength',
				label: 'Max length',
				preserve: true,
				help: 'What is the maximum length (in number of characters) of the input field. (optional)',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'number',
				name: 'minlength',
				label: 'Min length',
				preserve: true,
				help: 'What is the minimum length (in number of characters) of the input field. (optional)',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Validation parameters', BaseValidationOptions),
		...BuildElementBox('Conditional display', BaseConditionalOptions),
		...BuildElementBox('Expression display', BaseExpressionOptions),
		...BuildElementBox('Builder parameters', BaseBuilderOptions),
	];

	watch(
		() => data.name,
		(newNameValue) => {
			data.id = newNameValue;
		},
	);
</script>
