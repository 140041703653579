<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="checkboxSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { computed, watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BaseBuilderOptions, BaseConditionalOptions, BaseDesignOptions, BaseRequiredOptions, BaseSelectOptionsOptions, BaseValidationOptions, BuildElementBox } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = formStore.editingElement;

	// a computed ref
	const checkboxSchema = computed<FormKitSchemaNode[]>((): FormKitSchemaNode[] => {
		if (formStore.editingElement.meta?.type === 'group') {
			return [
				...BuildElementBox('Required options', BaseRequiredOptions),
				...BuildElementBox('Optional parameters', [
					{
						$formkit: 'text',
						name: 'label',
						label: 'Input label',
						preserve: true,
						help: 'What is the label of the input field. (optional)',
						outerClass: {
							'col-span-12': true,
							'sm:col-span-12': true,
							'lg:col-span-12': true,
							'xl:col-span-6': true,
							'px-1': true,
						},
					},
					{
						$formkit: 'text',
						name: 'help',
						label: 'Helper text',
						preserve: true,
						help: 'What is the helper of the input field. (optional)',
						outerClass: {
							'col-span-12': true,
							'sm:col-span-12': true,
							'lg:col-span-12': true,
							'xl:col-span-6': true,
							'px-1': true,
						},
					},
				]),
				...BuildElementBox('Checkbox group options', BaseSelectOptionsOptions),
				...BuildElementBox('Design parameters', BaseDesignOptions),
				...BuildElementBox('Validation parameters', BaseValidationOptions),
				...BuildElementBox('Builder parameters', BaseBuilderOptions),
			];
		}
		return [
			...BuildElementBox('Required options', BaseRequiredOptions),
			...BuildElementBox('Optional parameters', [
				{
					$formkit: 'text',
					name: 'label',
					label: 'Input label',
					preserve: true,
					help: 'What is the label of the input field. (optional)',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-6': true,
						'px-1': true,
					},
				},
				{
					$formkit: 'text',
					name: 'help',
					label: 'Helper text',
					preserve: true,
					help: 'What is the helper of the input field. (optional)',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-6': true,
						'px-1': true,
					},
				},
			]),
			...BuildElementBox('Design parameters', BaseDesignOptions),
			...BuildElementBox('Validation parameters', BaseValidationOptions),
			...BuildElementBox('Conditional display', BaseConditionalOptions),
			...BuildElementBox('Builder parameters', BaseBuilderOptions),
		];
	});

	watch(
		() => data.name,
		(newNameValue) => {
			data.id = newNameValue;
		}
	);
</script>