<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="staticDisplayElementSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { ref, watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BuildElementBox, StaticDesignOptions } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = ref({
		label: formStore.editingElement?.children[0].children ?? undefined,
		text: formStore.editingElement?.children[1].children ?? undefined,
		class: formStore.editingElement?.attrs?.class ?? undefined,
	});

	watch(
		() => data.value.label,
		(newValue, oldValue) => {
			if (JSON.stringify(oldValue) != JSON.stringify(newValue)) {
				formStore.getEditingElement().children[0].children = newValue;
			}
		},
	);

	watch(
		() => data.value.text,
		(newValue, oldValue) => {
			if (JSON.stringify(oldValue) != JSON.stringify(newValue)) {
				formStore.getEditingElement().children[1].children = newValue;
			}
		},
	);

	const staticDisplayElementSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Design parameters', [
			{
				$formkit: 'text',
				name: 'label',
				label: 'Display label',
				preserve: true,
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'text',
				name: 'text',
				label: 'Display text',
				preserve: true,
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Design parameters', StaticDesignOptions),
	];
</script>
