<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="ratingSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BaseBuilderOptions, BaseConditionalOptions, BaseDesignOptions, BaseRequiredOptions, BaseValidationOptions, BasicOptionalOptions, BuildElementBox } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = formStore.editingElement;

	const ratingSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Required options', BaseRequiredOptions),
		...BuildElementBox('Optional parameters', BasicOptionalOptions),
		...BuildElementBox('Design parameters', BaseDesignOptions),
		...BuildElementBox('Number input parameters', [
			{
				$formkit: 'number',
				name: 'step',
				label: 'Number step',
				preserve: true,
				value: '1',
				max: '1',
				min: '0',
				step: '0.1',
				help: 'What is the step that the number should be changeable by. (optional)',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Validation parameters', BaseValidationOptions),
		...BuildElementBox('Conditional display', BaseConditionalOptions),
		...BuildElementBox('Builder parameters', BaseBuilderOptions),
	];

	watch(
		() => data.name,
		(newNameValue) => {
			data.id = newNameValue;
		}
	);
</script>
