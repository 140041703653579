<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="staticElementSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { ref, watch } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BuildElementBox, StaticDesignOptions } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = ref({
		text: formStore.editingElement.children[0].children[1].children[0].children ?? undefined,
		align: formStore.editingElement.children[0].children[1].attrs?.class ?? undefined,
		borderThickness: formStore.editingElement.children[0].children[0].children[0].attrs?.class ?? undefined,
		borderColour: formStore.editingElement.children[0].children[0].children[0].attrs?.style ?? undefined,
		class: formStore.editingElement.attrs?.class ?? undefined,
	});

	watch(
		() => data.value.text,
		(newValue, oldValue) => {
			if (JSON.stringify(oldValue) != JSON.stringify(newValue)) {
				formStore.getEditingElement().children[0].children[1].children[0].children = newValue;
			}
		},
	);

	watch(
		() => data.value.align,
		(newValue, oldValue) => {
			if (JSON.stringify(oldValue) != JSON.stringify(newValue)) {
				formStore.getEditingElement().children[0].children[1].attrs.class = newValue;
			}
		},
	);

	watch(
		() => data.value.borderThickness,
		(newValue, oldValue) => {
			if (JSON.stringify(oldValue) != JSON.stringify(newValue)) {
				formStore.getEditingElement().children[0].children[0].children[0].attrs.class = newValue;
			}
		},
	);

	watch(
		() => data.value.borderColour,
		(newValue, oldValue) => {
			if (JSON.stringify(oldValue) != JSON.stringify(newValue)) {
				formStore.getEditingElement().children[0].children[0].children[0].attrs.style = newValue;
			}
		},
	);

	const staticElementSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Design parameters', [
			{
				$formkit: 'text',
				name: 'text',
				label: 'Divider text',
				preserve: true,
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'classes',
				name: 'align',
				label: 'Text alignment',
				preserve: true,
				directReturn: true,
				classOptions: {
					'justify-start': 'Left',
					'justify-center': 'Center',
					'justify-end': 'Right',
				},
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'classes',
				name: 'borderThickness',
				label: 'Divider Thickness',
				preserve: true,
				directReturn: true,
				classOptions: {
					'border-t': 'Normal',
					border: 'Little thicker',
					'border-2': 'Thick',
					'border-4': 'Thicker',
					'border-8': 'Ludicrous',
				},
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
			{
				$formkit: 'colour',
				name: 'borderColour',
				label: 'Line colour',
				preserve: true,
				directReturn: true,
				styleAttribute: 'border-color',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Design parameters', StaticDesignOptions),
	];
</script>
