<template>
	<AppLayout>
		<template #header>
			<PageHeader :title="formStore.activeForm.name" :show-breadcrumbs="true" :base-breadcrumb="baseBreadcrumb" :breadcrumbs="breadcrumbs()">
				<template #actions>
					<button
						class="flex justify-center items-center px-4 py-2 my-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-white hover:bg-gray-100 focus:outline-none"
						type="button"
						@click="formStore.updateForm()"
					>
						Save form
						<FontAwesomeIcon :icon="faFloppyDisk" class="h-5 w-5 text-black ml-2" aria-hidden="true" />
					</button>
				</template>
			</PageHeader>
		</template>
		<template #primary>
			<div class="flex-col h-full w-full">
				<div class="flex-1 h-full w-full">
					<div class="flex h-full w-full min-h-98">
						<div
							class="w-72 flex-grow-0 flex-shrink-0 relative transition-all duration-500 transform z-10 bg-white"
							:class="{
								'-ml-72': !showElements,
								'ml-0': showElements,
							}"
						>
							<div class="flex flex-col space-y-2 h-full w-full bg-gray-50">
								<div class="pt-4 px-3 pb-2">
									<div class="relative">
										<input type="text" class="pl-8 pr-3 py-1.25 rounded-lg placeholder-gray-400 placeholder-opacity-100 w-full border p-1" placeholder="Search elements" />
										<FontAwesomeIcon :icon="faMagnifyingGlass" class="inline-block text-inherit h-1em overflow-visible -align-0.125em absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-dark-400" />
									</div>
								</div>
								<div class="flex w-full justify-center items-center">
									<div>
										<div class="flex flex-row justify-center">
											<span class="relative z-0 inline-flex shadow-sm rounded-md">
												<button
													type="button"
													class="relative inline-flex items-center px-8 py-2 rounded-l-md border text-sm font-medium hover:bg-gray-100 focus:z-10 focus:outline-none"
													:class="{
														'bg-primary-600': elementMode === ElementMode.Inputs,
														'bg-white': elementMode !== ElementMode.Inputs,
														'hover:bg-primary-700': elementMode === ElementMode.Inputs,
														'text-white': elementMode === ElementMode.Inputs,
														'text-gray-700': elementMode !== ElementMode.Inputs,
														'border-transparent': elementMode === ElementMode.Inputs,
														'border-gray-300': elementMode !== ElementMode.Inputs,
													}"
													@click="elementMode = ElementMode.Inputs"
												>
													Inputs
												</button>
												<button
													type="button"
													class="-ml-px relative inline-flex items-center px-8 py-2 rounded-r-md border text-sm font-medium hover:bg-gray-100 focus:z-10 focus:outline-none"
													:class="{
														'bg-primary-600': elementMode === ElementMode.Display,
														'bg-white': elementMode !== ElementMode.Display,
														'hover:bg-primary-700': elementMode === ElementMode.Display,
														'text-white': elementMode === ElementMode.Display,
														'text-gray-700': elementMode !== ElementMode.Display,
														'border-transparent': elementMode === ElementMode.Display,
														'border-gray-300': elementMode !== ElementMode.Display,
													}"
													@click="elementMode = ElementMode.Display"
												>
													Display
												</button>
											</span>
										</div>
									</div>
								</div>
								<div class="overflow-y-auto pt-1 px-5 pb-3 h-full">
									<div
										v-for="element in availableFormElements"
										:key="element.id"
										class="draggable-element flex items-center -ml-2.5 -mr-2.5 px-1.5 py-1.5 border border-primary-500 bg-primary-500 bg-opacity-0 border-opacity-0 rounded-lg transition-all shadow-none duration-300 cursor-pointer hover:shadow-box-strong dark:hover:shadow-box-strong-dark"
										draggable="true"
										:title="element.name"
										@dragstart="startDraggingItem(element)"
										@dragend="isDraggingFormElement = false"
										@click="addElementToForm(element)"
									>
										<div v-if="element.icon" class="bg-gray-100 text-gray-500 rounded flex items-center justify-center text-0.5sm w-8 h-8 flex-shrink-0 flex-grow-0 mr-3 dark:bg-dark-600 dark:text-dark-300">
											<FontAwesomeIcon :icon="element.icon"></FontAwesomeIcon>
										</div>
										<div class="">
											<div class="text-gray-800 font-semibold">{{ element.name }}</div>
											<div class="text-gray-500 text-xs">{{ element.description }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex-1 bg-gray-100 relative dark:bg-dark-900 min-w-120 z-20">
							<div class="select-none absolute top-2 sm:top-4 -left-6 sm:-left-6 transition-all duration-500 transform translate-x-full flex flex-col z-100">
								<div class="flex flex-col shadow-sm bg-white border z-100 divide-y">
									<UIButton :icon="faRectanglesMixed" :selected="builderMode === BuilderMode.Builder" :label-right="true" label="Build Mode" @click="changeBuildMode(BuilderMode.Builder)" />
									<UIButton :icon="faCode" :selected="builderMode === BuilderMode.Code" :label-right="true" label="Code Mode (View/edit the raw form schema)" @click="changeBuildMode(BuilderMode.Code)" />
									<UIButton :icon="faEye" :selected="builderMode === BuilderMode.Test" :label-right="true" label="Test mode (Test the form for functionality)" @click="changeBuildMode(BuilderMode.Test)" />
									<UIButton :icon="faPenToSquare" :selected="builderMode === BuilderMode.Preview" :label-right="true" label="Preview form (View in Desktop, Laptop, Tablet, and Mobile mode)" @click="changeBuildMode(BuilderMode.Preview)" />
								</div>
								<div v-if="builderMode == BuilderMode.Preview" class="flex flex-col shadow-sm bg-white border mt-2 divide-y">
									<UIButton
										:icon="faDesktop"
										:selected="displayMode === DisplayMode.Desktop"
										:disabled="!baseStore.supportsDesktop"
										:label-right="true"
										:label="'Desktop mode' + (!baseStore.supportsDesktop ? ' (Unavailable on current devices screen size)' : '')"
										@click="changeDisplayMode(DisplayMode.Desktop)"
									/>
									<UIButton
										:icon="faLaptop"
										:selected="displayMode === DisplayMode.Laptop"
										:disabled="!baseStore.supportsLaptop"
										:label-right="true"
										:label="'Laptop mode' + (!baseStore.supportsLaptop ? ' (Unavailable on current devices screen size)' : '')"
										@click="changeDisplayMode(DisplayMode.Laptop)"
									/>
									<UIButton
										:icon="faTablet"
										:selected="displayMode === DisplayMode.Tablet"
										:disabled="!baseStore.supportsTablet"
										:label-right="true"
										:label="'Tablet mode' + (!baseStore.supportsTablet ? ' (Unavailable on current devices screen size)' : '')"
										@click="changeDisplayMode(DisplayMode.Tablet)"
									/>
									<UIButton :icon="faMobile" :selected="displayMode === DisplayMode.Mobile" :label-right="true" label="Mobile mode" @click="changeDisplayMode(DisplayMode.Mobile)" />
								</div>
							</div>
							<div v-if="builderMode != BuilderMode.Preview" class="absolute bottom-2 sm:bottom-4 -left-7 sm:-left-6 transition-all duration-500 transform translate-x-full flex flex-col z-100">
								<div class="flex flex-col shadow-sm bg-white border">
									<UIButton v-if="showElements" :icon="faArrowLeftFromLine" :label-right="true" label="Close panel" @click="showElements = false" />
									<UIButton v-else :icon="faArrowRightFromLine" :label-right="true" label="Open panel" @click="showElements = true" />
								</div>
							</div>

							<div class="absolute inset-0 sm:px-14 px-4 transition-all duration-500 transform overflow-y-scroll">
								<div class="w-full mx-auto flex flex-col items-center transition-all transform my-8 sm:my-4 duration-300" style="max-width: calc(99999px + 5rem)">
									<div class="flex flex-row mx-auto transition-all duration-500 transform w-full">
										<div
											class="bg-white rounded-lg px-7 pb-6 pt-4 sm:p-10 shadow-box-circle mx-auto transition-all duration-500 transform"
											:class="{
												'w-full': iframeWidth == '100%' || builderMode !== BuilderMode.Preview,
											}"
										>
											<div v-if="formStore.loading && !formStore.formLoaded" class="flex flex-col flex-grow justify-center h-60">
												<Loading />
											</div>
											<div v-else>
												<SchemaBuilder
													v-if="builderMode == BuilderMode.Builder"
													:schema="formStore.activeSchema"
													:dragging-form-element="isDraggingFormElement"
													@sorting="startSortingItem"
													@dropped="(eventLocationIndex: number | NestedLocation, eventLocationArea: string) => addElementToForm(draggingFormElement, eventLocationIndex, eventLocationArea)"
													@dropped-nested="(eventLocationIndex: number | NestedLocation, eventLocationArea: string) => addElementToForm(draggingFormElement, eventLocationIndex, eventLocationArea)"
												/>
												<CodeMode v-else-if="builderMode === BuilderMode.Code" />
												<TestMode v-else-if="builderMode === BuilderMode.Test" />
												<PreviewMode v-else-if="builderMode === BuilderMode.Preview" :height="iframeHeight" :width="iframeWidth" />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="builderMode != BuilderMode.Preview" class="absolute top-2 sm:top-4 -right-6 sm:-right-2 transition-all duration-500 transform -translate-x-full flex flex-col z-50">
								<div class="flex flex-col shadow-sm bg-white border z-50 divide-y">
									<UIButton :icon="faArrowRotateLeft" :disabled="!formStore.canUndo" :label="'Undo' + (!formStore.canUndo ? ' (No changes to undo)' : '')" @click="formStore.undo()" />
									<UIButton :icon="faArrowRotateRight" :disabled="!formStore.canRedo" :label="'Redo' + (!formStore.canUndo ? ' (No changes to redo)' : '')" @click="formStore.redo()" />
								</div>
								<div class="flex flex-col shadow-sm bg-white border mt-2 z-50">
									<UIButton :icon="faGear" label="Form global settings" :selected="formStore.formSettingsOpen" @click="formStore.formSettingsOpen ? formStore.closeFormSettingsEditor() : formStore.openFormSettingsEditor()" />
								</div>
								<div class="flex flex-col shadow-sm bg-white border mt-2 z-50">
									<UIButton
										:icon="faFloppyDisk"
										:icon-classes="{ 'animate-ping': formStore.loading && formStore.formLoaded }"
										label="Save form"
										:classes="{
											'active:bg-primary': true,
											'active:text-white': true,
										}"
										@click="formStore.updateForm()"
									/>
								</div>
							</div>

							<div v-if="builderMode != BuilderMode.Preview" class="absolute bottom-2 sm:bottom-4 -right-6 sm:-right-2 transition-all duration-500 transform -translate-x-full flex flex-col z-50">
								<div class="flex flex-col shadow-sm bg-white border">
									<UIButton :icon="faTrashCan" label="Clear form" @click="formStore.clearActiveSchema()" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #secondary_title_text>
			<span v-if="formStore.elementSettingsOpen"> Edit form element </span>
			<span v-else-if="formStore.formSettingsOpen"> Edit form settings </span>
		</template>
		<template #secondary>
			<ElementEditor v-if="formStore.elementSettingsOpen"></ElementEditor>
			<FormEditor v-else-if="formStore.formSettingsOpen"></FormEditor>
		</template>
	</AppLayout>
</template>

<script setup lang="ts">
	import AppLayout from '@layouts/AppLayout.vue';
	import ElementEditor from '@modules/form/components/ElementEditor.vue';
	import SchemaBuilder from '@modules/form/components/SchemaBuilder.vue';
	import { useFormStore } from '@modules/form/store';
	import { useBaseStore } from '@store';
	import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
	import { ElementType, FormElement, FormKitSchemaObject, NestedLocation } from '@/types/form';
	import { availableElements } from '../utils/elements';
	import FormEditor from '@modules/form/components/FormEditor.vue';
	import { WindowBreakpoint } from '@/types/app';
	import { onBeforeRouteLeave, useRoute } from 'vue-router';
	import useDialog from '@utils/useDialog';
	import Loading from '@components/Loading.vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import {
		faArrowLeftFromLine,
		faArrowRightFromLine,
		faArrowRotateLeft,
		faArrowRotateRight,
		faCode,
		faDesktop,
		faEye,
		faFloppyDisk,
		faGear,
		faLaptop,
		faMagnifyingGlass,
		faMobile,
		faPenToSquare,
		faRectanglesMixed,
		faTablet,
		faTrashCan,
	} from '@fortawesome/pro-light-svg-icons';
	import { BreadcrumbObject } from '@/types/layout';
	import PageHeader from '@components/PageHeader.vue';
	import CodeMode from '@modules/form/partials/CodeMode.vue';
	import TestMode from '@modules/form/partials/TestMode.vue';
	import PreviewMode from '@modules/form/partials/PreviewMode.vue';
	import UIButton from '@components/UIButton.vue';
	import { get } from 'lodash';
	import clone from '@utils/useClone';

	const formStore = useFormStore();
	const baseStore = useBaseStore();
	const route = useRoute();
	baseStore.secondaryPanelOpen = false;

	const enum DisplayMode {
		Desktop = 'Desktop',
		Laptop = 'Laptop',
		Tablet = 'Tablet',
		Mobile = 'Mobile',
	}

	const enum ElementMode {
		Inputs = 'Inputs',
		Display = 'Display',
	}

	const enum BuilderMode {
		Builder = 'Builder',
		Test = 'Test',
		Preview = 'Preview',
		Code = 'Code',
	}

	const elementMode = ref<string>(ElementMode.Inputs);
	const builderMode = ref<string>(BuilderMode.Builder);
	const displayMode = ref<string>(DisplayMode.Desktop);
	const showElements = ref(!baseStore.isMobile && !baseStore.isTablet);
	const isDraggingFormElement = ref(false);
	const draggingFormElement = ref();
	const draggingFormElementAddress = ref();
	const draggingFormElementIndex = ref();

	const baseBreadcrumb = {
		route: { name: 'form.index' },
		label: 'Forms',
	} as BreadcrumbObject;

	const breadcrumbs = () => {
		const breadcrumbs = [];

		for (const matchedRoute of route.matched) {
			breadcrumbs.push({
				route: {
					name: matchedRoute.name,
					params: {
						form_id: route.params.form_id.toString(),
					},
				},
				label: matchedRoute.meta.title ? matchedRoute.meta.title : formStore.activeForm.name,
			});
		}
		return (breadcrumbs as BreadcrumbObject[]).filter((element) => {
			return Object.keys(element).length !== 0;
		});
	};

	const availableFormElements = computed<FormElement[]>(() => {
		if (elementMode.value == ElementMode.Inputs) {
			return (
				availableElements.filter(function (el) {
					return el.type == ElementType.Field;
				}) as FormElement[]
			).sort((a, b) => a.name.localeCompare(b.name));
		} else {
			return (
				availableElements.filter(function (el) {
					return el.type == ElementType.Static;
				}) as FormElement[]
			).sort((a, b) => a.name.localeCompare(b.name));
		}
	});

	function determineIfFormElement(toBeDetermined: FormElement | FormKitSchemaObject): toBeDetermined is FormElement {
		return !!(toBeDetermined as FormElement).icon;
	}

	const addElementToForm = (elementToAdd: FormElement | FormKitSchemaObject, atIndex: NestedLocation | number | null = null, atLocation: string | null = null) => {
		if (determineIfFormElement(elementToAdd)) {
			formStore.addElementToActiveSchema(formStore.addUniqueIdToNewElement(elementToAdd.schema), typeof atIndex === 'number' ? (atLocation == 'top' ? atIndex : atIndex + 1) : atIndex);
		} else {
			if (typeof atIndex === 'number' && !draggingFormElementAddress.value) {
				formStore.makeUpdateToActiveSchema(<FormKitSchemaObject[]>array_move(formStore.activeSchema, draggingFormElementIndex.value, atIndex));
			} else {
				const theActiveSchema = clone(formStore.activeSchema);
				if (atIndex !== null && typeof atIndex !== 'number' && draggingFormElementAddress.value === atIndex?.elementAddress) {
					const parentObject = get(theActiveSchema, atIndex?.elementAddress);
					array_move(parentObject, draggingFormElementIndex.value, atLocation === 'top' && atIndex.insertAt ? atIndex.insertAt : atIndex.insertAt ?? 0 - 1);
				} else {
					const oldObject = get(theActiveSchema, !draggingFormElementAddress.value ? [draggingFormElementIndex.value] : (draggingFormElementAddress.value ?? '') + '.[' + draggingFormElementIndex.value + ']');
					const newObject = typeof atIndex === 'number' || !atIndex?.elementAddress ? theActiveSchema : get(theActiveSchema, atIndex?.elementAddress);
					if (!draggingFormElementAddress.value) {
						theActiveSchema.splice(draggingFormElementIndex.value, 1);
					} else {
						const oldObjectParent = get(theActiveSchema, draggingFormElementAddress.value);
						oldObjectParent.splice(draggingFormElementIndex.value, 1);
					}
					if (atIndex !== null) {
						newObject.splice((typeof atIndex === 'number' ? atIndex : atIndex?.insertAt ?? 0) + (atLocation === 'top' ? 0 : 1), 0, oldObject);
					}
				}
				formStore.makeUpdateToActiveSchema(theActiveSchema);
			}
		}

		if (draggingFormElement.value) {
			draggingFormElement.value = null;
			isDraggingFormElement.value = false;
			draggingFormElementIndex.value = null;
			draggingFormElementAddress.value = null;
		}
	};

	function array_move(arr: unknown[], old_index: number, new_index: number) {
		if (new_index >= arr.length) {
			let k = new_index - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr; // for testing
	}

	const changeDisplayMode = (newDisplayMode: DisplayMode) => {
		if (newDisplayMode == DisplayMode.Mobile && baseStore.supportsMobile) {
			displayMode.value = DisplayMode.Mobile;
			return;
		}
		if (newDisplayMode == DisplayMode.Tablet && baseStore.supportsTablet) {
			displayMode.value = DisplayMode.Tablet;
			return;
		}
		if (newDisplayMode == DisplayMode.Laptop && baseStore.supportsLaptop) {
			displayMode.value = DisplayMode.Laptop;
			return;
		}
		if (newDisplayMode == DisplayMode.Desktop && baseStore.supportsDesktop) {
			displayMode.value = DisplayMode.Desktop;
			return;
		}
	};

	const changeBuildMode = (newBuildMode: BuilderMode) => {
		if ((newBuildMode == BuilderMode.Builder || newBuildMode == BuilderMode.Code) && (baseStore.isDesktop || baseStore.isLaptop)) {
			showElements.value = true;
		} else if (newBuildMode == BuilderMode.Test || newBuildMode == BuilderMode.Preview) {
			showElements.value = false;
		}
		builderMode.value = newBuildMode;
	};

	const startDraggingItem = (element: FormElement) => {
		isDraggingFormElement.value = true;
		draggingFormElement.value = element;
	};

	const startSortingItem = (element: FormKitSchemaObject, atIndex: number | NestedLocation, elementAddress: string | undefined | null = null) => {
		isDraggingFormElement.value = true;
		draggingFormElement.value = element;
		draggingFormElementIndex.value = atIndex;
		draggingFormElementAddress.value = elementAddress;
	};

	type iframeHeightEvent = {
		data: {
			height: number;
		};
	};

	const resizeIframeFromMessage = (iframeHeightEvent: iframeHeightEvent) => {
		if ('height' in iframeHeightEvent.data) {
			iframeHeight.value = iframeHeightEvent.data.height + 'px';
		}
	};
	const iframeHeight = ref('0px');
	const iframeWidth = computed(() => {
		if (displayMode.value === DisplayMode.Desktop) {
			return '100%';
		} else if (displayMode.value === DisplayMode.Laptop) {
			if (baseStore.windowWidth < 1100 + 304) {
				return '100%';
			}
			return '1100px';
		} else if (displayMode.value === DisplayMode.Tablet) {
			if (baseStore.windowWidth < 700 + 304) {
				return '100%';
			}
			return '700px';
		} else if (displayMode.value === DisplayMode.Mobile) {
			if (baseStore.windowWidth < 400 + 304) {
				return '100%';
			}
			return '400px';
		}
		return '100%';
	});

	const setDisplayModeFromBreakpoint = (breakpoint: WindowBreakpoint) => {
		if (breakpoint == WindowBreakpoint.Laptop) {
			if (displayMode.value == DisplayMode.Desktop) {
				changeDisplayMode(DisplayMode.Laptop);
			}
		} else if (breakpoint == WindowBreakpoint.Tablet) {
			if (displayMode.value == DisplayMode.Desktop || displayMode.value == DisplayMode.Laptop) {
				changeDisplayMode(DisplayMode.Tablet);
			}
		} else if (breakpoint == WindowBreakpoint.Mobile) {
			if (displayMode.value == DisplayMode.Desktop || displayMode.value == DisplayMode.Laptop || displayMode.value == DisplayMode.Tablet) {
				changeDisplayMode(DisplayMode.Mobile);
			}
		}
	};

	watch(
		() => baseStore.currentBreakpoint,
		(newBreakpoint) => {
			setDisplayModeFromBreakpoint(newBreakpoint);
		},
	);

	onMounted(async () => {
		window.addEventListener('message', resizeIframeFromMessage, false);
		setDisplayModeFromBreakpoint(baseStore.currentBreakpoint);
		await formStore.getFormById(route.params.form_id.toString());
	});

	onBeforeRouteLeave(async () => {
		if (formStore.hasUnsaved) {
			const confirmResult = await useDialog.title('Whoaaa hold up there!').confirm('Do you really want to leave? you have unsaved changes!');
			// cancel the navigation and stay on the same page
			if (!confirmResult.isConfirmed) {
				return false;
			}
		}
	});

	onUnmounted(async () => {
		window.removeEventListener('message', resizeIframeFromMessage, false);
		await formStore.resetFormStore();
	});
</script>

<style></style>
