<template>
	<FormKit v-model="data" type="form" :actions="false">
		<div class="grid grid-cols-12 gap-x-2">
			<FormKitSchema :schema="staticElementSchema" />
		</div>
	</FormKit>
</template>

<script setup lang="ts">
	import { ref } from 'vue';
	import { useFormStore } from '@modules/form/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BuildElementBox, StaticDesignOptions } from '@modules/form/utils/options';

	const formStore = useFormStore();

	const data = ref({
		style: formStore.editingElement?.attrs?.style ?? '',
		class: formStore.editingElement?.attrs?.class ?? '',
	});

	const staticElementSchema: FormKitSchemaNode[] = [
		...BuildElementBox('Spacer specific parameters', [
			{
				$formkit: 'classes',
				name: 'class',
				label: 'Spacer height',
				preserve: true,
				staticElement: true,
				classOptions: {
					'h-1': 'Level 1',
					'h-2': 'Level 2',
					'h-3': 'Level 3',
					'h-4': 'Level 4',
					'h-5': 'Level 5',
					'h-6': 'Level 6',
					'h-7': 'Level 7',
					'h-8': 'Level 8',
					'h-9': 'Level 9',
					'h-10': 'Level 10',
					'h-11': 'Level 11',
					'h-12': 'Level 12',
					'h-14': 'Level 14',
				},
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-6': true,
					'px-1': true,
				},
			},
		]),
		...BuildElementBox('Design parameters', StaticDesignOptions),
	];
</script>
